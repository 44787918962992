import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "moment";

export default class TimeRemaining extends Component {
  state = {
    timeRemaining: this.calculateTimeRemaining(),
  };

  calculateTimeRemaining() {
    let quotedTime = Moment(this.props.createdTime).add(
      this.props.minsQuoted,
      "m"
    );

    if (quotedTime.isBefore(Moment())) {
      return null;
    }
    return quotedTime.subtract(Moment()).format("mm : ss ");
  }

  componentDidMount() {
    setInterval(() => {
      if (this.calculateTimeRemaining()) {
        this.setState({
          timeRemaining: this.calculateTimeRemaining(),
        });
      } else {
        this.setState({
          timeRemaining: "Past quoted time",
        });
      }
    }, 1000);
  }

  render() {
    return <div>{this.state.timeRemaining}</div>;
  }
}
