import React from "react";
import Moment from "moment";
import axios from "axios";
import { withRouter } from "react-router-dom";
import TimeRemaining from "./containers/TimeRemaining";
import TimePicker from "./containers/TimePicker";
import NumberPicker from "./containers/NumberPicker";
import {
  Input,
  Button,
  Container,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
  Navbar,
  FormGroup,
  Table,
  Form,
} from "reactstrap";
class ReservationForm extends React.Component {
  constructor(props, location) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      guestName: "",
      isSat: false,
      childNum: 0,
      adultNum: 0,
      minsQuoted: 0,
      resNotes: "",
      locationID: 1,
      isActive: true,
      tableData: [],
    };
    this.handlesubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.tableDataHandler();
  }

  tableDataHandler() {
    axios.get(`/api/testwaitlistforonelocation`).then((response) => {
      this.setState({
        tableData: response.data,
      });
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.isUpdate) {
      // Update
      axios
        .put(`/api/reservations/update/${this.state.id}`, this.state)
        .then((response) => {
          console.log(response);
          this.resetForm();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Insert
      axios
        .post(`/api/reservation`, this.state)
        .then((response) => {
          console.log(response);
          alert("Reservation Saved");
          this.resetForm();
        })
        .catch((err) => {
          console.log(err);
          alert("Could not save");
        });
    }
  }

  resetForm() {
    this.setState({
      guestName: "",
      isSat: false,
      childNum: 0,
      adultNum: 0,
      minsQuoted: 0,
      resNotes: "",
      locationID: 1,
    });

    this.isUpdate = false;

    setTimeout(() => {
      this.tableDataHandler();
    }, 100);
  }

  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  edit(e) {
    if (e) {
      this.isUpdate = true;
      this.setState({
        id: e.id,
        guestName: e.guestName,
        adultNum: e.adultNum,
        childNum: e.childNum,
        resNotes: e.resNotes,
        minsQuoted: e.minsQuoted,
        createdTime: e.createdTime,
      });
    }
  }

  seated(id) {
    console.log(id);
    axios
      .put(`/api/reservations/update/isSat/${id}`, this.state)
      .then((response) => {
        console.log(response);
        this.isUpdate = false;
        this.resetForm();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updateMinsQuoted = (minsQuoted) => {
    console.log(minsQuoted);
    this.setState({
      minsQuoted: minsQuoted,
    });
  };

  updateNumberValueChild = (numberValue) => {
    console.log(numberValue);
    this.setState({
      childNum: numberValue,
    });
  };

  updateNumberValueAdult = (numberValue) => {
    console.log(numberValue);
    this.setState({
      adultNum: numberValue,
    });
  };

  render() {
    // isFormValid = () => {
    //   const {guestName, adultNum, minsQuoted } = this.state
    //   return guestName && adultNum && minsQuoted
    // }
    Moment.locale("en");
    const { tableData = [] } = this.state;
    return (
      <div>
        <Navbar color="primary" variant="dark">
          <NavbarBrand href="/">
            {/* <img
              className="center-block"
              src="http://via.placeholder.com/80x80"
              alt="description"
            /> */}
            <span style={{ color: "white", fontSize: "xx-large" }}>
              Waitlist
            </span>
          </NavbarBrand>
          <Nav className="mr-auto">
            <NavLink href="#!">
              {" "}
              <span style={{ color: "white", fontSize: "large" }}>
                Reservations
              </span>
            </NavLink>
            <NavLink href="#features">
              {" "}
              <span style={{ color: "white", fontSize: "large" }}>Pricing</span>
            </NavLink>
            <NavLink href="#pricing">
              {" "}
              <span style={{ color: "white", fontSize: "large" }}>
                Features
              </span>
            </NavLink>
          </Nav>
        </Navbar>
        <Container style={{ marginTop: "20px" }} fluid="sm">
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    bsSize="md"
                    placeholder="Name"
                    name="guestName"
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    value={this.state.guestName}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  {/* <Input
                  bsSize="md"
                  placeholder="🕺 Adults"
                  name="adultNum"
                  type="number"
                  onChange={this.handleChange.bind(this)}
                  value={this.state.adultNum}
                /> */}
                  <NumberPicker
                    type="a"
                    value={this.state.adultNum}
                    onChange={this.updateNumberValueAdult}
                    updateNumberValue={this.updateNumberValueAdult}
                  />
                </FormGroup>
              </Col>
              <Col>
                {/* <Input
                bsSize="md"
                placeholder="👶 Kids"
                name="childNum"
                type="number"
                onChange={this.handleChange.bind(this)}
                value={this.state.childNum}
              /> */}
                <NumberPicker
                  type="c"
                  value={this.state.childNum}
                  onChange={this.updateNumberValueAdult}
                  updateNumberValue={this.updateNumberValueChild}
                />
              </Col>
              <Col>
                <FormGroup>
                  {/* <Input
                  bsSize="md"
                  placeholder="🕑 Wait"
                  name="minsQuoted"
                  type="number"
                  onChange={this.handleChange.bind(this)}
                  value={this.state.minsQuoted}
                /> */}
                  <TimePicker
                    minsQuoted={this.state.minsQuoted}
                    onChange={this.updateMinsQuoted}
                    updateMinsQuoted={this.updateMinsQuoted}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    bsSize="md"
                    placeholder="📝 Notes"
                    name="resNotes"
                    type="textarea"
                    onChange={this.handleChange.bind(this)}
                    value={this.state.resNotes}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {/*console.log(JSON.stringify(this.state))*/}
                <Button
                  size="lg"
                  color="primary"
                  block
                  type="submit"
                  onClick={this.handleSubmit.bind(this)}
                  onSubmit={this.handleSubmit.bind(this)}
                  disabled={
                    !this.state.adultNum > 0 ||
                    !this.state.guestName.length > 1 ||
                    !this.state.minsQuoted > 0
                  }
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>

          <Row style={{ marginTop: "20px" }}>
            <Col>
              <Table responsive striped hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Adults</th>
                    <th>Kids</th>
                    <th>Notes</th>
                    <th>Created</th>
                    <th>Time Remaining</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.length ? (
                    tableData.map((tableData) => (
                      <tr key={tableData.id}>
                        <td>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={this.seated.bind(this, tableData.id)}
                          >
                            🪑
                          </span>
                          &nbsp;
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={this.edit.bind(this, tableData)}
                          >
                            🛠️
                          </span>
                        </td>

                        <td>{tableData.guestName}</td>
                        <td>{tableData.adultNum}</td>
                        <td>{tableData.childNum}</td>
                        <td>{tableData.resNotes}</td>
                        <td>
                          {Moment(tableData.createdTime).format("h:mm a")}
                        </td>
                        <td>
                          <TimeRemaining
                            createdTime={tableData.createdTime}
                            minsQuoted={tableData.minsQuoted}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default ReservationForm;
