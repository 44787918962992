import React, { Component } from "react";
import Picker from "react-scrollable-picker";

function generateNumberArray(begin, end, type) {
  let array = [];
  for (let i = begin; i <= end; i++) {
    if ((type === "m" && i % 5 == 0) || type === "h") {
      array.push({
        value: i,
        label: i,
      });
    }
  }
  return array;
}

export default class TimePicker extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      isPickerShow: false,
      valueGroups: {
        hours: 0,
        minutes: 0,
      },
      optionGroups: {
        hours: generateNumberArray(0, 10, "h"),
        minutes: generateNumberArray(0, 60, "m"),
      },
    };
  }

  handleChange = (name, value) => {
    this.setState(
      ({ valueGroups }) => ({
        valueGroups: {
          ...valueGroups,
          [name]: value,
        },
      }),
      () => {
        this.props.updateMinsQuoted(
          this.state.valueGroups.hours * 60 + this.state.valueGroups.minutes
        );
      }
    );
  };

  togglePicker = () => {
    this.setState(({ isPickerShow }) => ({
      isPickerShow: !isPickerShow,
    }));
  };

  render() {
    const { isPickerShow, optionGroups, valueGroups } = this.state;
    const maskStyle = {
      display: isPickerShow ? "block" : "none",
    };
    const textAlign = {
      textAlign: "center",
      color: "grey",
      fontWeight: "bold",
    };

    const minutes = {
      textAlign: "center",
      color: "grey",
      fontWeight: "bold",
      paddingRight: "50px",
    };
    const pickerModalClass = `picker-modal${
      isPickerShow ? " picker-modal-toggle" : ""
    }`;
    const okButton = {
      textAlign: "center",
      color: "#0275d8",
      fontWeight: "bold",
    };

    return (
      <div className="example-container">
        <div className="weui_cells">
          <div className="weui_cell weui_cell_select weui_select_after">
            <div className="weui_cell_bd weui_cell_primary">
              <input
                type="text"
                className="form-control-md form-control"
                placeholder="🕑 Wait"
                value={this.props.minsQuoted ? this.props.minsQuoted : ""}
                style={{ backgroundColor: "white" }}
                onClick={this.togglePicker}
                onChange={this.props.onChange}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="picker-modal-container">
          <div
            className="picker-modal-mask"
            style={maskStyle}
            onClick={this.togglePicker}
          ></div>
          <div className={pickerModalClass}>
            <header>
              <div style={textAlign} className="col-md-6">
                Hours
              </div>

              <a href="#!" style={okButton} onClick={this.togglePicker}>
                OK
              </a>

              <div style={minutes} className="col-md-6">
                Minutes
              </div>
            </header>
            <Picker
              optionGroups={optionGroups}
              valueGroups={valueGroups}
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
